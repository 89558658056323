<script setup>

import {ref} from "vue";
import {usePage} from "@inertiajs/inertia-vue3";

defineEmits(['update:modelValue']);
const props = defineProps({
    modelValue: {
        default: null
    }
});

const exploitants_list = ref(usePage().props.value.exploitants);
</script>

<template>
    <select id="exploitant_choice" class="form-control" :value="modelValue"
            @change="$emit('update:modelValue', $event.target.value)" required>
        <option v-if="exploitants_list.length > 1" disabled>Sélectionnez...</option>
        <option v-for="option in exploitants_list" :value="option.id" :key="option.id" selected>
            {{ option.fullname }}
        </option>
    </select>
</template>
