<script setup>
import BreezeButton from '@/Components/Button.vue';
import BoGuestLayout from '@/Layouts/GuestLayout.vue';
import BoButton from "@/Components/Button.vue";
import BoInput from '@/Components/Input.vue';
import BoValidationErrors from '@/Components/ValidationErrors.vue';
import BoLoginBoxTitle from "@/Components/LoginBoxTitle.vue";
import InputAppend from "@/Components/InputAppend.vue";
import { Head, useForm } from '@inertiajs/inertia-vue3';

const props = defineProps({
    email: String,
    token: String,
});

const form = useForm({
    token: props.token,
    email: props.email,
    password: '',
    password_confirmation: '',
});

const submit = () => {
    form.post(route('password.update'), {
        onFinish: () => form.reset('password', 'password_confirmation'),
    });
};
</script>

<template>
    <BoGuestLayout>
        <Head title="Reset Password" />
        <div class="login-box">
            <BoLoginBoxTitle/>
            <div class="card">
                <div class="card-body login-card-body">
                    <BoValidationErrors class="mb-4" />

                    <form @submit.prevent="submit">
                        <div class="input-group mb-3">
                            <BoInput id="email" placeholder="Votre email" type="email" v-model="form.email" autocomplete="username" required autofocus/>
                            <InputAppend icon="fa-envelope" />
                        </div>

                        <div class="input-group mb-3">
                            <BoInput id="password" placeholder="Nouveau mot de passe" type="password" v-model="form.password" required autocomplete="new-password" />
                            <InputAppend icon="fa-lock" />
                        </div>

                        <div class="input-group mb-3">
                            <BoInput id="password_confirmation" placeholder="Confirmation" type="password" v-model="form.password_confirmation" required autocomplete="new-password" />
                            <InputAppend icon="fa-lock" />
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <BoButton type="submit"
                                          class="ms-4 btn-block btn-primary"
                                          :class="{ 'text-white-50': form.processing }"
                                          :disabled="form.processing">
                                    <div v-show="form.processing" role="status">
                                        <span class="sr-only">Chargement...</span>
                                    </div>
                                    Enregistrer mon mot de passe
                                </BoButton>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </BoGuestLayout>
</template>
