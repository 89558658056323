<script setup>
import UserDropDown from "@/Components/UserDropDown.vue";
import ToggleFullScreenButton from "@/Components/ToggleFullScreenButton.vue";
import {ref} from "vue";

const emit = defineEmits(['sidebarToggleCollapse']);
const props = defineProps({
    collapsed: {
        type: Boolean,
        default: false,
    },
});
const isCollapsed = ref(props.collapsed)

const toggleSideBar = (e) => {
    isCollapsed.value = !isCollapsed.value;
    emit("sidebarToggleCollapse", isCollapsed.value, e);
}


</script>
<template>
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
        <ul class="navbar-nav">
            <li class="nav-item">
                <button @click="toggleSideBar" class="btn nav-link" type="button"><i class="fas fa-bars"></i></button>
            </li>
        </ul>
        <ul class="navbar-nav ml-auto">
            <UserDropDown/>
            <li class="nav-item d-none d-sm-inline-block">
                <ToggleFullScreenButton/>
            </li>
        </ul>
    </nav>
</template>
