<script setup>

import Datepicker from '@vuepic/vue-datepicker';

/**
 * Format date for the datepicker for the view
 * @param date
 * @returns {`Du ${string|*}/${string|*}/${number} au ${string|*}/${string|*}/${number}`}
 */
const format_dates_filters = (date) => {
    const startDate = date[0],
        endDate = date[1],
        startDateDay = startDate.getDate() <= 9 ? "0" +  startDate.getDate() :  startDate.getDate() ,
        startDateMonth = ((startDate.getMonth() + 1) <= 9 ) ? "0" + (startDate.getMonth() + 1) : (startDate.getMonth() + 1),
        startDateYear = startDate.getFullYear(),
        endDateDay = endDate.getDate() <= 9 ? "0" +  endDate.getDate() :  endDate.getDate() ,
        endDateMonth = ((endDate.getMonth() + 1) <= 9 ) ? "0" + (endDate.getMonth() + 1) : (endDate.getMonth() + 1),
        endDateYear = endDate.getFullYear();
    return `Du ${startDateDay}/${startDateMonth}/${startDateYear} au ${endDateDay}/${endDateMonth}/${endDateYear}`;
}
</script>

<template>
    <Datepicker
                range
                autoApply
                multiCalendars
                :monthChangeOnScroll="false"
                :enableTimePicker="false"
                :maxDate="new Date()"
                position="right"
                inputClassName="dp-dowino-range-input"
                monthNameFormat="long"
                modelType="yyyy-MM-dd"
                :format="format_dates_filters"
                :placeholder="__('ui.datepicker.SelectPeriod')"
                :locale="this.$page.props.locale"></Datepicker>
</template>
