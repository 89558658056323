<template>
    <div class="icheck-primary">
        <input :id="id" type="checkbox" v-model="model" :value="value" />
        <label class="form-check-label" :for="id">{{ label }}</label>
    </div>
</template>

<script setup>
import { computed, defineEmits } from "vue";
const props = defineProps({
    modelValue: { type: [Array, Boolean] },
    value: { type: [Boolean, Object, String] },
    label: { type: String, default:'' },
    id: { type: String, required:true },
});

const emit = defineEmits(["update:modelValue"]);
const model = computed({
    get() {
        return props.modelValue;
    },
    set(value) {
        emit("update:modelValue", value);
    },
});
</script>
