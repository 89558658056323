import {usePage} from "@inertiajs/inertia-vue3";

/**
 * Check current user role
 * @param role_to_check
 * @returns {{return: boolean}}
 */
const hasRole = (role_to_check) => {

    const current_user_role_slugs_list = usePage().props.value.auth.user.roles.map(role => role.slug);
    return current_user_role_slugs_list.includes(role_to_check);
}

/**
 * Check current user permission
 * @type {{return: boolean}}
 */
const hasPermission = (permission_to_check) => {
    const user = usePage().props.value.auth.user;
    const current_user_permissions_slugs_list = [];
    user.roles.map((role) => {
        const permissions_role = role.permissions;
        permissions_role.map( permission =>{
            if(!current_user_permissions_slugs_list.includes(permission.slug)){
                current_user_permissions_slugs_list.push(permission.slug)
            }
        })
    })
    return current_user_permissions_slugs_list.includes(permission_to_check);
}

export  {hasRole, hasPermission};
