import {
    addDataToUpdateQueue,
    removeDataToUpdateQueue,
    synchronizeLocalToServer
} from "./localData.js";
import {
    destroyDistantDiagnostic,
    destroyLocalDiagnostic,
    synchronizeDiagnostics
} from "./diagnostics.js";
import {synchronizeReferentiels} from "./referentiels.js";
import {IdeleLocalIndexedDB} from "./ideleIndexedDb.js";
import {synchronizeExploitants} from "@/services/synchro_data/exploitants.js";

export const useSynchroLocalData = () => {
    const synchronizeDataOnLogin = async () => {
        console.log("Debut de synchronisation de toutes les données...");
        try {
            const db = await IdeleLocalIndexedDB.initConnection();
            await synchronizeLocalToServer(db);
            // Appeler les fonctions asynchrones en parallèle
            await Promise.all([
                //todo:syncho des exploitants comme sur la webapp
                synchronizeExploitants(db),
                synchronizeDiagnostics(db),
                synchronizeReferentiels()
            ]);
            await IdeleLocalIndexedDB.closeConnection(db);
            // Si toutes les fonctions se terminent avec succès, afficher un message de réussite
            console.log('Toutes les données synchronisées avec succès.');
        } catch (error) {
            // Si une erreur se produit dans l'une des fonctions, la capturer ici
            console.error('Une erreur s\'est produite lors de la synchronisation des données :', error);
        }
    }
    const synchronizeDiagnosticsOnDemand = async() => {
        console.log("Demande de synchronisation manuelle des diagnostics...");
        try {
            const db = await IdeleLocalIndexedDB.initConnection();
            await synchronizeLocalToServer(db);
            const res = await synchronizeDiagnostics(db);
            await IdeleLocalIndexedDB.closeConnection(db);
            console.log('Tous les diagnostics synchronisées manuellement avec succès.');
            return res;
        }
        catch (error) {
            // Si une erreur se produit dans l'une des fonctions, la capturer ici
            console.error('Une erreur s\'est produite lors de la synchronisation manuelle des diagnostics :', error);
        }
    }

    /**
     * Suprresion manuelle d'un diagnistic depuis l'administration
     * @param id
     * @returns {Promise<void>}
     */
    const deleteDiagnostic = async (id) => {
        console.log("Demande de suppression manuelle d'un diagnostic...");
        try {
            const db = await IdeleLocalIndexedDB.initConnection();
            // ajout du diagnostic à la file d'attente de suppression
            const idUpdateLocal = await addDataToUpdateQueue(db, 'destroy', 'diagnostic', id, {id});
            // tentative de suppression du diagnostic en bdd
            await destroyDistantDiagnostic(id)
                .then(() => {
                    removeDataToUpdateQueue(db, idUpdateLocal)
                })
                .catch(error => {
                    console.error(`Erreur lors de la suppression du diagnostic ${id} en BDD distante`);
                    console.log(error);
                });
            await destroyLocalDiagnostic(db, id)

            await IdeleLocalIndexedDB.closeConnection(db);
            console.log('Suppression manuelle d\'un diagnostic fini.');
        }
        catch (error) {
            // Si une erreur se produit dans l'une des fonctions, la capturer ici
            console.error('Une erreur s\'est produite lors de la suppression manuelle d\'un diagnostic :', error);
        }
    }

    return {
        synchronizeDataOnLogin,
        synchronizeDiagnosticsOnDemand,
        deleteDiagnostic
    };
}
