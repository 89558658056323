<script setup>
import {computed, ref, watch} from "vue";
import {useForm, usePage} from "@inertiajs/inertia-vue3";
import ExploitantField from "@/Pages/Diagnostics/partials/ExploitantField.vue";
import {hasRole} from "@/Helpers/useRoles.js";
import BoInput from "@/Components/Input.vue";
import {notify} from "@kyvg/vue3-notification";

const emit = defineEmits(['cancelEditing', 'diag_added', 'diag_updated']);

const props = defineProps({
    itemToEdit: {
        type: Object,
        default: null
    },
});

const editMode = computed(() => props.itemToEdit !== null);

const exploitants_list = ref(usePage().props.value.exploitants);

const formDiag = useForm({
    user_id: exploitants_list.value.length === 1 ? exploitants_list.value[0].id : null,
    name: null,
    is_model: false
});
watch(() => props.itemToEdit, (newDiag) => {
    formDiag.clearErrors();
    if (newDiag !== null) {
        formDiag.user_id = newDiag.user_id;
        formDiag.name = newDiag.name;
        formDiag.is_model = newDiag.is_model;
    }else{
        formDiag.reset();
    }
});
const submit = () => {
    if (props.itemToEdit === null) {
        formDiag.post(route('admin.diagnotics.store'), {
            onSuccess: () => {
                formDiag.reset();
                const success_msg = usePage().props.value.flash.success;
                if (success_msg) {
                    notify({
                        title: "Diagnotic crée",
                        text: success_msg,
                        type: "success",
                    })
                }
                emit("diag_added")
            },
        })
    }else{
        formDiag.put(route('admin.diagnotics.update', props.itemToEdit.id), {
            onSuccess: () => {
                formDiag.reset();
                const success_msg = usePage().props.value.flash.success;
                if (success_msg) {
                    notify({
                        title: "Diagnotic modifié",
                        text: success_msg,
                        type: "success",
                    })
                }
                emit("diag_updated");
                emit('cancelEditing');
            },
        })
    }
}
</script>

<template>
    <div class="card animate">
        <div class="card-header">
            <h3 class="card-title">
                <span v-if="editMode">
                    <i class="fas fa-file-alt"></i>
                    Modifier un diagnostic
                </span>
                <span v-else>
                    <i class="fas fa-file-alt"></i>
                    Créer un diagnostic
                </span>
            </h3>
        </div>
        <form @submit.prevent="submit">
            <div class="card-body">
                <div v-if="!hasRole('exploitant')" class="form-group">
                    <label for="exploitant_choice">
                        Pour quel.le exploitant.e ?
                        <sup class="text-danger">*</sup>
                    </label>
                    <ExploitantField :class="{'is-invalid': formDiag.errors.user_id}" v-model="formDiag.user_id"/>
                    <div class="invalid-feedback" v-if="formDiag.errors.user_id">
                        {{ formDiag.errors.user_id }}
                    </div>
                </div>
                <div class="form-group">
                    <label for="exploitant_choice">
                        Nom du diagnostic <sup class="text-danger">*</sup>
                    </label>
                        <BoInput id="firstname" placeholder="Nom du diagnostic" type="text"
                                 v-model="formDiag.name"
                                 :class="{'is-invalid': formDiag.errors.name}"
                                 required/>
                </div>
                <div class="form-group">
                    <strong>Exploitation modèle ?</strong>
                    <div class="form-check pl-0" :class="{'is-invalid': formDiag.errors.is_model}">

                        <div class="icheck-primary">
                            <input id="is_model_true" type="radio" required v-model="formDiag.is_model" :value="true">
                            <label for="is_model_true" class="form-check-label">
                                Oui
                            </label>
                        </div>
                        <div class="icheck-primary">
                            <input id="is_model_false" type="radio" required v-model="formDiag.is_model" :value="false">
                            <label for="is_model_false" class="form-check-label">
                                Non
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-sm-6" v-if="editMode">
                        <button type="button" @click="$emit('cancelEditing')" class="btn btn-block btn-default"><i
                            class="fas fa-undo"></i> {{ __('ui.administrator.Cancel') }}
                        </button>
                    </div>
                    <div :class="{'col-sm-12': !editMode, 'col-sm-6': editMode}">
                        <button type="submit" :disabled="formDiag.processing" class="btn btn-block btn-primary"><i
                            class="fas fa-save"></i> {{ __('ui.administrator.Register') }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>
