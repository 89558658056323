<script setup>
import BoButton from '@/Components/Button.vue';
import BoGuestLayout from '@/Layouts/GuestLayout.vue';
import BoInput from '@/Components/Input.vue';
import BreezeValidationErrors from '@/Components/ValidationErrors.vue';
import { Head,Link, useForm } from '@inertiajs/inertia-vue3';
import LoginBoxTitle from "@/Components/LoginBoxTitle.vue";
import {computed} from "vue";
import InputAppend from "@/Components/InputAppend.vue";


const props = defineProps({
    canResetPassword: Boolean,
    canRegister: Boolean,
    status: String,
});
const form = useForm({
    email: '',
});

const submit = () => {
    form.post(route('password.email'));
};
const loginLinkCssClass = computed(() => {
    return {
        "mb-0" : !props.canRegister,
        "mb-1" : props.canRegister,
    }
});
</script>

<template>
    <BoGuestLayout>
        <Head title="Mot de passe oublié"/>
        <div class="login-box">
            <LoginBoxTitle/>
            <div class="card">
                <div class="card-body login-card-body">
                    <p class="login-box-msg">
                        <strong>Vous avez oublié votre mot de passe ?</strong>
                        Saisissez votre email, vous pourrez définir un nouveau mot de passe.
                    </p>

                    <div v-if="status" class="alert alert-success mt-2" role="alert">
                        {{ status }}
                    </div>

                    <BreezeValidationErrors class="mb-4 mt-2" />

                    <form @submit.prevent="submit">
                        <div class="input-group mt-3 mb-3">
                            <BoInput id="email" type="email" v-model="form.email" required autofocus autocomplete="username" />
                            <InputAppend icon="fa-envelope"/>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <BoButton class="btn-primary btn-block" :class="{ 'text-white-50': form.processing }" :disabled="form.processing">
                                    <div v-show="form.processing" role="status">
                                        <span class="sr-only">Chargement...</span>
                                    </div>
                                    Envoyer le lien de résiliation
                                </BoButton>
                            </div>
                        </div>
                    </form>
                    <p class="mt-3" :class="loginLinkCssClass">
                        <Link :href="route('login')">
                            Connexion
                        </Link>
                    </p>
                    <p class="mb-0" v-if="canRegister">
                        <Link :href="route('register')">
                            Inscription
                        </Link>
                    </p>
                </div>
            </div>
        </div>



    </BoGuestLayout>
</template>
