<template>
    <ul class="pl-4 mb-0">
        <li>{{ props.diagnostic.exploitant.organisme_name }} - {{yearCreation}}</li>
        <li>Créé par <strong>{{ created_by }}</strong><br/>le {{ created_date }}</li>
        <template v-if="props.diagnostic.created_at !== props.diagnostic.updated_at">
            <li>Modifié par <strong>{{ updated_by }}</strong><br/>le {{ updated_date }}</li>
        </template>
        <template v-if="props.diagnostic.is_lock_for_exploitant">
            <li>Verrouillé pour l'exploitant</li>
        </template>
        <li>Campagne : {{ yearCampaign !== null ? yearCampaign : "Année non définie" }}</li>
    </ul>
<!--    <pre>{{diagnostic}}</pre>-->
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
    diagnostic: {
        type: Object,
        default: null
    },
});
const yearCampaign = computed(() => props.diagnostic.diag_content?.data?.m000?.moduleData?.STARTYEARCAMPAIGN);
const yearCreation = computed(() => format_year(props.diagnostic.created_at))
const created_by = computed(() => props.diagnostic.created_by_user.fullname )
const created_date = computed(() => format_date(props.diagnostic.created_at))

const updated_by = computed(() => props.diagnostic.updated_by_user.fullname )
const updated_date = computed(() => format_date(props.diagnostic.updated_at))

const format_year= (date_from_json) => {
    const optionsYear = {
        timeZone: "Europe/Paris",
        year: "numeric",
    }
    const date = new Date(date_from_json);
    const formatterYear = new Intl.DateTimeFormat("fr-FR", optionsYear);
    return formatterYear.format(date)
}
const format_date = (date_from_json) => {
    if (date_from_json) {
        const options = {
            timeZone: "Europe/Paris",
        }
        const optionsDate = {
            ...options,
            day: "numeric",
            month: "short",
            year: "numeric",
        };
        const optionsHeure = {
            ...options,
            hour: '2-digit',
            minute: '2-digit',
        };
        const date = new Date(date_from_json);
        const formatterDate = new Intl.DateTimeFormat("fr-FR", optionsDate);
        const formatterHeure = new Intl.DateTimeFormat("fr-FR", optionsHeure);

        const dateFormatted = formatterDate.format(date);
        const heureFormatted = formatterHeure.format(date);
        return `${dateFormatted} à ${heureFormatted}`;
    }
    return '';

}
</script>

<style scoped>

</style>
