<script setup>

import {computed, onMounted, onUnmounted, ref} from "vue";
import {Link, usePage} from '@inertiajs/inertia-vue3';
import {mapValues, pick} from "lodash/object";


const showingUserDropdown = ref(false);
const root = ref(null)

onMounted(() => {
    document.body.addEventListener("click", closeUserDropDown);
});
onUnmounted( () => {
    document.body.removeEventListener("click", closeUserDropDown);
})

const closeUserDropDown = (e) => {
    if (! root.value.contains(e.target)) {
        showingUserDropdown.value = false
    }
};
const initiales = computed(() => {

    const user = usePage().props.value.auth.user;

    const nom = user.lastname ? user.lastname.toString() : '';
    const prenom = user.firstname ? user.firstname.toString() : '';

    // Obtenez la première lettre de chaque nom et prénom
    const nomInitial = nom.charAt(0).toUpperCase();
    const prenomInitial = prenom.charAt(0).toUpperCase();
    return prenomInitial + nomInitial;
})
const user_roles = computed(() => mapValues(usePage().props.value.auth.user.roles, 'name'))
</script>
<template>
    <li ref="root" class="nav-item dropdown user-menu" :class={show:showingUserDropdown}>
        <a href="#" class="nav-link dropdown-toggle"  @click="showingUserDropdown = ! showingUserDropdown;" :aria-expanded="showingUserDropdown">
            <div class="user-image">{{initiales}}</div>
            <span class="d-none d-md-inline">{{ $page.props.auth.user.fullname }}</span>
        </a>
        <ul class="dropdown-menu dropdown-menu-lg dropdown-menu-right" :class={show:showingUserDropdown}>

            <li class="user-header bg-dark">
                <div class="user-image">{{initiales}}</div>
                <p>
                    {{ $page.props.auth.user.fullname }}
                    <br v-if="Object.keys(user_roles).length"/>
                    <span class="badge badge-info" :class="{'mr-1': index < Object.keys(user_roles).length - 1}" v-for="(role, index) in user_roles" :key="index">
                        <small>{{role}}</small>
                    </span>
                </p>
            </li>

            <li class="user-footer">
                <Link :href="route('admin.my-profile.index')" class="btn btn-default btn-flat"><i class="fas fa-id-badge"></i> {{ __('ui.userDropDown.MyProfile') }}</Link>
                <Link class="btn btn-default btn-flat float-right" :href="route('logout')" method="post" as="button">
                    <i class="fas fa-sign-out-alt"></i> {{ __('ui.userDropDown.Logout') }}
                </Link>
            </li>
        </ul>
    </li>
</template>
