<script setup>
import { computed } from 'vue';
import BreezeButton from '@/Components/Button.vue';
import BoGuestLayout from '@/Layouts/GuestLayout.vue';
import { Head, Link, useForm } from '@inertiajs/inertia-vue3';
import BoLoginBoxTitle from "@/Components/LoginBoxTitle.vue";

const props = defineProps({
    status: String,
});

const form = useForm();

const submit = () => {
    form.post(route('verification.send'));
};

const verificationLinkSent = computed(() => props.status === 'verification-link-sent');
</script>

<template>
    <BoGuestLayout body-class="register-page">
        <Head title="Email Verification" />
        <div class="login-box">
            <BoLoginBoxTitle/>
            <div class="card">
                <div class="card-body register-card-body">
                    <p class="register-box-msg">
                        Merci de vous être inscrit ! Avant de commencer, pourriez-vous vérifier votre
                        adresse e-mail en cliquant sur le lien que nous venons de vous envoyer par e-mail ?
                        Si vous n'avez pas reçu l'e-mail, nous serons heureux de vous en envoyer un autre.
                    </p>
                    <div class="alert alert-success mt-3" role="alert" v-if="verificationLinkSent">
                        Un nouveau lien de vérification a été envoyé à l'adresse email que vous avez fournie
                        lors de votre inscription.
                    </div>
                    <form @submit.prevent="submit">
                        <div class="row">
                            <div class="col-12">
                                <BreezeButton class="btn-block btn-primary mt-3" :class="{ 'opacity-25': form.processing }" :disabled="form.processing">
                                    Renvoyer le mail de vérification
                                </BreezeButton>
                            </div>
                        </div>
                        <Link :href="route('logout')"
                              method="post"
                              as="button"
                              class="btn btn-link px-0 mt-2">Se déconnecter
                        </Link>

                    </form>
                </div>
            </div>
        </div>
    </BoGuestLayout>
</template>
