<script setup>
import {computed, watch, ref} from "vue";
import {useForm, usePage} from "@inertiajs/inertia-vue3";
import BoInput from "@/Components/Input.vue";
import InputAppend from "@/Components/InputAppend.vue";
import {notify} from "@kyvg/vue3-notification";
/*import BoCheckbox from "@/Components/Checkbox";
import {map} from "lodash/collection";
import {pick} from "lodash/object";*/
const emit = defineEmits(['cancelEditing']);
const props = defineProps({
    itemToEdit: {
        type: Object,
        default: null
    },
});
const form = useForm({
    name: '',
    slug: '',
});
const editMode = computed(() => props.itemToEdit !== null);

/**
 * Watcher for params
 * Make an Inertia request with cleaned params
 */

watch(() => props.itemToEdit, (newItemToEdit) => {
    if (newItemToEdit !== null) {
        form.defaults({
            name: newItemToEdit.name,
            slug: newItemToEdit.slug,
        })
    } else {
        form.defaults({
            name: '',
            slug: '',
        })

    }
    form.clearErrors();
    form.reset();
});


const submit = () => {
    if (props.itemToEdit === null) {
        form.post(route('admin.permissions.store'), {
            onSuccess: () => {
                form.defaults({
                    name: '',
                    slug: '',
                })
                form.reset();
                const success_msg = usePage().props.value.flash.success;
                if (success_msg) {
                    notify({
                        title: usePage().props.value.language['ui.permission.Added'],
                        text: usePage().props.value.language['ui.permission.SuccessMsgAdded'],
                        type: "success",
                    })
                }

            },
        })
    } else {
        form.put(route('admin.permissions.update', props.itemToEdit.id), {
            onSuccess: () => {
                form.defaults({
                    name: '',
                    slug: '',
                })
                form.reset();
                const success_msg = usePage().props.value.flash.success;
                if (success_msg) {
                    notify({
                        title: usePage().props.value.language['ui.permission.Modified'],
                        text: usePage().props.value.language['ui.permission.SuccessMsgModified'],
                        type: "success",
                    });
                }
                emit('cancelEditing');
            },
        })
    }
};

</script>
<template>
    <div class="card animate">
        <div class="card-header">
            <h3 class="card-title">
                <span v-if="editMode">
                    <i class="fas fa-pen-alt"></i>
                    {{ __('ui.permission.ModifyAPermission') }}
                </span>
                <span v-else>
                    <i class="fas fa-plus-circle"></i>
                    {{ __('ui.permission.AddAPermission') }}
                </span>
            </h3>
        </div>
        <form @submit.prevent="submit">
            <div class="card-body">
                <div class="form-group">
                    <label for="name">{{ __('ui.permission.PermissionName') }} <sup class="text-danger">*</sup></label>
                    <div class="input-group" :class="{'is-invalid': form.errors.name}">
                        <BoInput id="name" :placeholder="__('ui.permission.PermissionName')" type="text" v-model="form.name"
                                 :class="{'is-invalid': form.errors.name}" autocomplete="name" autofocus required />
                        <InputAppend icon="fa-signature"/>
                    </div>
                    <div class="invalid-feedback" v-if="form.errors.name">
                        {{ form.errors.name }}
                    </div>
                </div>
                <div class="form-group">
                    <label for="slug">{{ __('ui.role.InternalNameSlug') }} <sup class="text-danger">*</sup></label>
                    <div class="input-group" :class="{'is-invalid': form.errors.slug}">
                        <BoInput type="text" autocomplete="slug" class="form-control"
                                 :class="{'is-invalid': form.errors.slug}" id="slug" v-model="form.slug"
                                 :placeholder="__('ui.role.InternalNameSlug')"  required/>
                        <InputAppend icon="fa-database"/>
                    </div>
                    <div class="invalid-feedback" v-if="form.errors.slug">
                        {{ form.errors.slug }}
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="row">
                    <div class="col-sm-6" v-if="editMode">
                        <button type="button" @click="$emit('cancelEditing')" class="btn btn-block btn-default"><i
                            class="fas fa-undo"></i> {{ __('ui.administrator.Cancel') }}
                        </button>
                    </div>
                    <div :class="{'col-sm-12': !editMode, 'col-sm-6': editMode}">
                        <button type="submit" :disabled="form.processing" class="btn btn-block btn-primary"><i
                            class="fas fa-save"></i> {{ __('ui.administrator.Register') }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

