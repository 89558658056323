import {gsap} from "gsap";
let el_to_animate = null;
const onEnterOpacity = (el, done) =>{
    gsap.to(el_to_animate, {
        opacity:1,
        y:0,
        stagger: { // wrap advanced options in an object
            // from: "random",
            each:.1,
        },
        onComplete:done,
        duration:.5,
        ease:"power2.out",
        clearProps:"all"
    })
}
const onBeforeEnterOpacity = (el) =>{
    el_to_animate = el.querySelectorAll(".animate");

    gsap.set(el_to_animate, {opacity:0,y:10})
}

export { onEnterOpacity, onBeforeEnterOpacity }
