<script setup>
import BreezeButton from '@/Components/Button.vue';
import BoGuestLayout from '@/Layouts/GuestLayout.vue';
import BoInput from '@/Components/Input.vue';
import BreezeLabel from '@/Components/Label.vue';
import BoValidationErrors from '@/Components/ValidationErrors.vue';
import {Head, Link, useForm} from '@inertiajs/inertia-vue3';
import BoLoginBoxTitle from "@/Components/LoginBoxTitle.vue";
import BoButton from "@/Components/Button.vue";
import InputAppend from "@/Components/InputAppend.vue";

const form = useForm({
    firstname: '',
    lastname: '',
    email: '',
    password: '',
    password_confirmation: '',
    terms: false,
});

const submit = () => {
    form.post(route('register'), {
        onFinish: () => form.reset('password', 'password_confirmation'),
    });
};
</script>

<template>
    <BoGuestLayout body-class="register-page">
        <Head title="Register"/>
        <div class="register-box">
            <BoLoginBoxTitle/>
            <div class="card">
                <div class="card-body register-card-body">
                    <form @submit.prevent="submit">
                        <div class="form-group">
                            <div class="input-group" :class="{'is-invalid': form.errors.firstname}">
                                <BoInput id="firstname" :class="{'is-invalid': form.errors.firstname}"
                                         placeholder="Votre prénom" type="text" v-model="form.firstname"
                                         autocomplete="name" autofocus required/>
                                <InputAppend icon="fa-user-alt"/>
                            </div>
                            <div class="invalid-feedback" v-if="form.errors.firstname">
                                {{ form.errors.firstname }}
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-group" :class="{'is-invalid': form.errors.lastname}">
                                <BoInput id="lastname" placeholder="Votre nom" type="text" v-model="form.lastname"
                                         :class="{'is-invalid': form.errors.lastname}"
                                         autocomplete="name"/>
                                <InputAppend icon="fa-user-alt"/>
                            </div>

                            <div class="invalid-feedback" v-if="form.errors.lastname">
                                {{ form.errors.lastname }}
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-group" :class="{'is-invalid': form.errors.email}">
                                <BoInput id="email" type="email" placeholder="Votre email" v-model="form.email"
                                         autocomplete="username"
                                         :class="{'is-invalid': form.errors.email}" required/>
                                <InputAppend icon="fa-envelope"/>
                            </div>

                            <div class="invalid-feedback" v-if="form.errors.email">
                                {{ form.errors.email }}
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-group" :class="{'is-invalid': form.errors.password}">
                                <BoInput id="password" placeholder="Mot de passe" type="password"
                                         v-model="form.password"
                                         :class="{'is-invalid': form.errors.password}"
                                         required
                                         autocomplete="new-password"/>
                                <InputAppend icon="fa-lock"/>
                            </div>

                            <div class="invalid-feedback" v-if="form.errors.password">
                                {{ form.errors.password }}
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-group">
                                <BoInput id="password_confirmation" placeholder="Confirmation" type="password"
                                         v-model="form.password_confirmation" required
                                         autocomplete="new-password"/>
                                <InputAppend icon="fa-lock"/>
                            </div>
                        </div>
                        <div class="mb-0">
                            <BoButton class="ms-4 btn-block btn-primary" :class="{ 'text-white-50': form.processing }"
                                      :disabled="form.processing">
                                <div v-show="form.processing" role="status">
                                    <span class="sr-only">Chargement...</span>
                                </div>
                                S'enregistrer
                            </BoButton>
                        </div>
                    </form>
                    <p class="mb-0 mt-3">
                        <Link :href="route('login')">
                            J'ai déjà un compte
                        </Link>
                    </p>
                </div>
            </div>
        </div>

    </BoGuestLayout>
</template>
