<script setup>

import AuthenticatedFrontLayout from "@/Layouts/AuthenticatedFrontLayout.vue";
import {computed} from "vue";
import {usePage} from "@inertiajs/inertia-vue3";

const diag = computed(() => usePage().props.value.diagnostic);
</script>

<template>
    <AuthenticatedFrontLayout>
        <h2>{{diag.name}}</h2>
    </AuthenticatedFrontLayout>

</template>
