<template>
    <div class="card animate">
        <div class="card-header">
            <h3 class="card-title">
                <i class="fa fa-solid fa-plus"></i>
                Créer un atelier personnalisé
            </h3>
        </div>
        <form @reset.prevent="form.reset()" @submit.prevent="submit">
            <div class="card-body">
                <div class="mb-3">
                    <label for="vs-cat-atelier" class="form-label">Atelier parent <sup class="text-danger">*</sup></label>
                    <v-select v-model.number="form.cat"
                              inputId="vs-cat-atelier"

                              placeholder="Sélectionnez"
                              class="mb-2 d-print-none"
                              :options="standardAtelier">
                        <template #option="option">
                            <div :class="`lvl_${option.lvl}`">
                                {{ option.label }}
                            </div>
                        </template>
                    </v-select>
                </div>
                <div class="mb-3">
                    <label for="cat-atelier-name" class="form-label">Nom de l'atelier <sup class="text-danger">*</sup></label>
                    <input type="text"
                           id="cat-atelier-name"
                           class="form-control"
                           v-model.trim="form.label"/>
                </div>
            </div>
            <div class="card-footer">
                <div class="d-flex justify-content-between">
                    <button type="reset" class="btn btn-default btn-icon-text">
                        <i class="fas fa-undo"></i> Annuler
                    </button>
                    <button type="submit" class="btn btn-primary btn-icon-text" :disabled="submitDisabled">
                        <i class="fas fa-save"></i> Enregistrer
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<script setup>

import {computed, ref, watch} from "vue";
import {useForm, usePage} from "@inertiajs/inertia-vue3";
import {notify} from "@kyvg/vue3-notification";

const props = defineProps({
    standardAtelier: {
        type:Array,
        required:true,
        default:[]
    },
});


const form = useForm({cat:null, label:null})

// valeur calculée pour l'état disabled du bouton de soumission
const submitDisabled = computed(() => form.cat === null || form.label === null || form.label.toString().trim() === "")

 const submit = () => {
     form.post(route('admin.custom-ateliers.store'), {
         onSuccess: () => {
             form.reset();
             const success_msg = usePage().props.value.flash.success;
             if (success_msg) {
                 notify({
                     title: "Atelier crée",
                     text: success_msg,
                     type: "success",
                 })
             }
         }
     })
 }



</script>
<style>
@import "vue-select/dist/vue-select.css";
div.lvl_1{
    font-weight: bold;
}
div.lvl_2{
    padding-left: 15px;
}
div.lvl_3{
    padding-left: 30px;
    font-size: .9rem;
}
div.lvl_4{
    padding-left: 45px;
    font-size: .9rem;
}
div.lvl_5{
    padding-left: 60px;
    font-size: .9rem;
}
</style>
