import CryptoJS from "crypto-js/core.js";
import CBC from "crypto-js"

const encrypt_log_for_bo = (string, passphrase) => {
    if(!passphrase || passphrase === undefined){
        console.warn("No key specified for Dowino FrontLog encryption.");
        return string;
    }
    let iv = CryptoJS.lib.WordArray.random(16),
        key = CryptoJS.enc.Base64.parse(passphrase);
    let options = {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    };
    let encrypted = CryptoJS.AES.encrypt(string, key, options);
    encrypted = encrypted.toString();
    iv = CryptoJS.enc.Base64.stringify(iv);
    let result = {
        iv: iv,
        value: encrypted,
        mac: CryptoJS.HmacSHA256(iv + encrypted, key).toString()
    }
    result = JSON.stringify(result);
    result = CryptoJS.enc.Utf8.parse(result);
    return CryptoJS.enc.Base64.stringify(result);
}
/**
 * Générate a md5 for the given string
 * @param string
 * @returns {*}
 */
const generate_hash_log = (string) => {
    return CryptoJS.MD5(string)
}

export { generate_hash_log, encrypt_log_for_bo }
