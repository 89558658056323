const IdeleLocalIndexedDB = {
    dbName: 'IdeleDB',

    dbVersion: 1,

    diagnosticStoreName: "diagnostic",         // Nom du store pour les diagnostics
    exploitantStoreName: "exploitants",         // Nom du store pour les exploitants
    pendingActionsStoreName: "pendingActions",     // Nom du store pour les modèles à updater
    customAteliersStoreName: "customAteliers",     // Nom du store pour les ateliers custom

    diagnosticStore: null,                          // Store stockant les diagnostics de l'utilisateur
    exploitantStore:null,                           // Store stockant les exploitants relatifs à l'utilisateur
    pendingActionsStore: null,                      // Store stockant les modèles à updater
    customAteliersStore:null,                       // Store stockant les ateliers custom

    /**
     * Ouvre la connexion avec la base de données locale
     * @returns {Promise<unknown>}
     */
    initConnection: async function () {
        return new Promise((resolve, reject) => {
            const DBOpenReq = window.indexedDB.open(this.dbName, this.dbVersion);
            DBOpenReq.addEventListener('error', (event) => {
                reject(event.target.error)
            })

            DBOpenReq.addEventListener('success', (event) => {
                //console.log(`   Connecté à la base ${IdeleLocalIndexedDB.dbName} locale`);
                // Résoudre la promesse avec la connexion à la base de données
                resolve(event.target.result);
            });

            DBOpenReq.addEventListener('upgradeneeded', (event) => {
                buildStores(event)
            })
        })
    },

    /**
     * Ferme la connexion avec la base de données locale db
     * @param db
     * @returns {Promise<unknown>}
     */
    closeConnection: async function (db) {
        return new Promise((resolve, reject) => {
            // Fermer la connexion à la base de données
            //console.log(`   Fermeture de la connexion à la base ${IdeleLocalIndexedDB.dbName} locale`);
            db.close();

            // Résoudre la promesse une fois que la connexion est fermée
            resolve();
        });
    },

    /**
     * Crée une transaction sur un store donné avec un mode (lecture, lecture/écriture)
     * @param db
     * @param storeName
     * @param mode
     * @returns {*}
     */
    makeTX: function (db, storeName, mode) {
        const tx = db.transaction(storeName, mode);
        tx.onerror = (err) => {
            console.warn(err);
        };
        return tx;
    }
}

/**
 * Création des stores (tables) dans cette fonction. Si de nouveaux stores sont créés ou supprimés,
 * il faut changer la version de la bdd : IdeleLocalIndexedDB.dbVersion en l'incrémentant.
 * @param ev
 */
const buildStores = (ev) => {
    const db = ev.target.result,
        oldVersion = ev.oldVersion,
        newVersion = ev.newVersion || db.versions;
    console.log(`   ${IdeleLocalIndexedDB.dbName} updated from version ${oldVersion} to ${newVersion}`);

    // Store des diagnostics
    if (db.objectStoreNames.contains(IdeleLocalIndexedDB.diagnosticStoreName)) {
        db.deleteObjectStore(IdeleLocalIndexedDB.diagnosticStoreName)
    }
    IdeleLocalIndexedDB.diagnosticStore = db.createObjectStore(IdeleLocalIndexedDB.diagnosticStoreName, {
        autoIncrement: true
    });
    IdeleLocalIndexedDB.diagnosticStore.createIndex("idIDX", 'id', {unique: true})
    IdeleLocalIndexedDB.diagnosticStore.createIndex("localIdIDX", 'local_id', {unique: false})
    // IdeleLocalIndexedDB.diagnosticStore.createIndex("exploitantIDX", 'exploitant.fullname', {unique: false})
    console.log(`      -> Store ${IdeleLocalIndexedDB.diagnosticStoreName} crée avec succès.`);

    // Store pour les exploitants
    if (db.objectStoreNames.contains(IdeleLocalIndexedDB.exploitantStoreName)) {
        db.deleteObjectStore(IdeleLocalIndexedDB.exploitantStoreName)
    }
    IdeleLocalIndexedDB.exploitantStore = db.createObjectStore(IdeleLocalIndexedDB.exploitantStoreName, {
        autoIncrement: true
    });
    console.log(`      -> Store ${IdeleLocalIndexedDB.exploitantStoreName} crée avec succès.`);

    // Store pour les creates/update/delete
    if (!db.objectStoreNames.contains(IdeleLocalIndexedDB.pendingActionsStoreName)) {
        IdeleLocalIndexedDB.pendingActionsStore = db.createObjectStore(IdeleLocalIndexedDB.pendingActionsStoreName, {
            autoIncrement: true
        });
        IdeleLocalIndexedDB.pendingActionsStore.createIndex("actionIDX", 'action', {unique: false})
        IdeleLocalIndexedDB.pendingActionsStore.createIndex("modelIDX", 'model', {unique: false})
        IdeleLocalIndexedDB.pendingActionsStore.createIndex("idIDX", 'id', {unique: false})
    }
    console.log(`      -> Store ${IdeleLocalIndexedDB.pendingActionsStoreName} crée avec succès.`);

    // Store pour les ateliers custom
    if (db.objectStoreNames.contains(IdeleLocalIndexedDB.customAteliersStoreName)) {
        db.deleteObjectStore(IdeleLocalIndexedDB.customAteliersStoreName)
    }
    IdeleLocalIndexedDB.customAteliersStore = db.createObjectStore(IdeleLocalIndexedDB.customAteliersStoreName, {
        autoIncrement: true
    });
    IdeleLocalIndexedDB.customAteliersStore.createIndex("atelierFKIDX", 'atelier_FK', {unique: false})
    IdeleLocalIndexedDB.customAteliersStore.createIndex("lvlIDX", 'lvl', {unique: false})

    console.log(`      -> Store ${IdeleLocalIndexedDB.customAteliersStoreName} crée avec succès.`);

}

export {IdeleLocalIndexedDB}
