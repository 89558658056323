<script setup>
import BoAuthenticatedLayout from '@/Layouts/AuthenticatedLayout.vue';
import {Head, useForm, usePage} from '@inertiajs/inertia-vue3';
import {computed, ref} from "vue";
import BoInput from "@/Components/Input.vue";
import {notify} from "@kyvg/vue3-notification";
import {mapValues} from "lodash/object";
import {onEnterOpacity, onBeforeEnterOpacity} from "@/Helpers/animations";

const current_user = computed(() => usePage().props.value.auth.user);
const change_password_box_showed = ref(false);
const form = useForm({
    firstname: current_user.value.firstname,
    lastname: current_user.value.lastname,
    current_password: '',
    new_password: '',
    new_password_confirmation: '',
})

/**
 * Show/hide change pwd box
 */
const showChangePwdBox = (show) => {
    change_password_box_showed.value = show;
    if(!show){
        form.reset('current_password', 'new_password', 'new_password_confirmation')
    }
}

/**
 * Reset theform
 */
const cancelForm = () => {
    showChangePwdBox(false);
    form.clearErrors();
    form.reset();
}
const user_roles = computed(() => mapValues(usePage().props.value.auth.user.roles, 'name'))
/**
 * Submit the form
 */
const submit = () => {
    form
        .transform((data) => ({
            ...data,
            user_want_change_pwd: change_password_box_showed.value,
        }))
        .put(route('admin.my-profile.update'), {
            onFinish: () => form.reset('new_password', 'new_password_confirmation'),
            onSuccess: () => {
                showChangePwdBox(false)
                form.defaults({
                    firstname: current_user.value.firstname,
                    lastname: current_user.value.lastname,
                    current_password: '',
                    new_password: '',
                    new_password_confirmation: '',
                })
                form.reset();
                const success_msg = usePage().props.value.flash.success;
                if (success_msg) {
                    notify({
                        title: usePage().props.value.language['ui.myProfile.Modified'],
                        text: success_msg == 'Votre profil a bien été modifiée.' ?
                            usePage().props.value.language['ui.myProfile.SuccessMsgModified'] :
                            usePage().props.value.language['ui.myProfile.SuccessMsgModifiedProfileAndpassword'],
                        type: "success",
                    })
                }
            },
        })
}

</script>

<template>
    <Head title="Mon profil"/>

    <BoAuthenticatedLayout>
        <template #page_title>{{ __('ui.userDropDown.MyProfile') }}</template>
        <transition @before-enter="onBeforeEnterOpacity"
                    @enter="onEnterOpacity"
                    appear mode="out-in">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="card animate">
                            <div class="card-header">
                                <h3 class="card-title"><i class="fas fa-user-alt"></i> {{ __('ui.myProfile.ModifyMyUserProfile') }}</h3>
                            </div>
                            <form @submit.prevent="submit">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-sm-3 text-center">
                                            <img :src="current_user.gravatar" class="img-circle elevation-2" alt="User Image">
                                            <h3 class="profile-username text-center mt-4">{{ form.firstname }} {{ form.lastname }}</h3>
                                            <div v-if="Object.keys(user_roles).length">
                                            <span class="badge badge-info" :class="{'mr-1': index < Object.keys(user_roles).length - 1}" v-for="(role, index) in user_roles" :key="index">
                                                {{ __(role)}}
                                            </span>
                                            </div>
                                        </div>
                                        <div class="col-sm-9">
                                            <div class="form-group row">
                                                <label for="firstname" class="col-sm-2 col-form-label">{{ __('ui.administrator.Firstname') }} :</label>
                                                <div class="col-sm-10">
                                                    <BoInput id="firstname" :class="{'is-invalid': form.errors.firstname}"
                                                             :placeholder="__('ui.myProfile.YourFirstName')" type="text" v-model="form.firstname"
                                                             autocomplete="name" autofocus/>

                                                    <div class="invalid-feedback" v-if="form.errors.firstname">
                                                        {{ form.errors.firstname }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label for="lastname" class="col-sm-2 col-form-label">{{ __('ui.administrator.Name') }} :</label>
                                                <div class="col-sm-10">
                                                    <BoInput id="lastname" :class="{'is-invalid': form.errors.lastname}"
                                                             :placeholder="__('ui.myProfile.YourFirstName')" type="text" v-model="form.lastname"
                                                             autocomplete="name"/>

                                                    <div class="invalid-feedback" v-if="form.errors.lastname">
                                                        {{ form.errors.lastname }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <label class="col-sm-2 col-form-label">{{ __('ui.administrator.Email') }} :</label>
                                                <div class="col-sm-10">
                                                    <input type="text" readonly class="form-control-plaintext"
                                                           :value="current_user.email">
                                                </div>
                                            </div>
                                            <div v-if="change_password_box_showed">
                                                <div class="form-group row">
                                                    <label for="current_password" class="col-sm-2 col-form-label">{{ __('ui.myProfile.CurrentPassword') }}</label>
                                                    <div class="col-sm-10">
                                                        <BoInput id="current_password" :class="{'is-invalid': form.errors.current_password}"
                                                                 :placeholder="__('ui.myProfile.YourCurrentPassword')" type="password"
                                                                 v-model="form.current_password"
                                                                 autocomplete="current_password"/>

                                                        <div class="invalid-feedback" v-if="form.errors.current_password">
                                                            {{ form.errors.current_password }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="new_password" class="col-sm-2 col-form-label">{{ __('ui.myProfile.NewPassword') }}</label>
                                                    <div class="col-sm-10">
                                                        <BoInput id="new_password" :class="{'is-invalid': form.errors.new_password}"
                                                                 :placeholder="__('ui.myProfile.YourNewPassword')" type="password"
                                                                 v-model="form.new_password"
                                                                 autocomplete="new_password"/>

                                                        <div class="invalid-feedback" v-if="form.errors.new_password">
                                                            {{ form.errors.new_password }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group row">
                                                    <label for="new_password_confirmation" class="col-sm-2 col-form-label">{{ __('ui.myProfile.Confirmation') }}</label>
                                                    <div class="col-sm-10">
                                                        <BoInput id="new_password_confirmation" :class="{'is-invalid': form.errors.new_password_confirmation}"
                                                                 :placeholder="__('ui.myProfile.RetypeYourNewPassword')" type="password"
                                                                 v-model="form.new_password_confirmation"
                                                                 autocomplete="new_password_confirmation"/>

                                                        <div class="invalid-feedback" v-if="form.errors.new_password_confirmation">
                                                            {{ form.errors.new_password_confirmation }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <button type="button" class="btn btn-default btn-sm" @click="showChangePwdBox(false)" v-if="change_password_box_showed"><i class="fa fa-ban"></i> {{ __('ui.myProfile.DontChangeMyPassword') }}</button>
                                            <button type="button" class="btn btn-default btn-sm" @click="showChangePwdBox(true)" v-if="!change_password_box_showed"><i class="fa fa-lock"></i> {{ __('ui.myProfile.ChangeMyPassword') }}</button>
                                        </div>
                                    </div>

                                </div>
                                <div class="card-footer d-flex justify-content-between">
                                    <button class="btn btn-default mr-auto" type="button" @click="cancelForm">{{ __('ui.administrator.Cancel') }}</button>
                                    <button class="btn btn-primary" type="submit">{{ __('ui.administrator.Register') }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </BoAuthenticatedLayout>
</template>
