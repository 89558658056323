<script setup>
import {onUnmounted, onMounted, ref,} from 'vue';
import BoBreadcrumb from "@/Components/Breadcrumb.vue";
import BoMainNavigation from "@/Components/MainNavigation.vue";
import BoTopNavBar from "@/Components/TopNavBar.vue";
import {debounce} from "lodash/function";
import {Inertia} from "@inertiajs/inertia";
import { usePage } from '@inertiajs/inertia-vue3'
const autoCollapseSize = 992;
const rootsidebar = ref(null);
const props = defineProps({
    hasBreadcrumb: {
        type: Boolean,
        default: true,
    },
});
const toggleSidebar = (collapsed, e) => {
    Inertia.put(route('admin.my-profile.collapse-menu'),
        {
            collapse_menu: collapsed,
        },
        {
            replace: true,
        },
    );
    // si on est en petit écran
    if (document.body.classList.contains("sidebar-closed")) {
        document.body.classList.add("sidebar-open");
        document.body.classList.remove("sidebar-closed", "sidebar-collapse");
        e.stopPropagation();
        document.body.addEventListener("click", closeSideBarOnBodyClick);
    } else {
        if (collapsed) {
            document.body.classList.add("sidebar-collapse");
        } else {
            document.body.classList.remove("sidebar-collapse");
        }
    }
}
const closeSideBarOnBodyClick = (e) => {
    if (!rootsidebar.value?.menu.contains(e.target)) {
        document.body.classList.remove("sidebar-open");
        document.body.classList.add("sidebar-collapse", "sidebar-closed");
        document.body.removeEventListener("click", closeSideBarOnBodyClick);
    }


}

const debounceResizeWindow = debounce(() => {
    resizeWindow()
}, 2)
const resizeWindow = () => {
    if (window.innerWidth <= autoCollapseSize) {
        if (document.body.classList.contains("sidebar-open")) {
            document.body.classList.remove("sidebar-open");
            document.body.classList.add("sidebar-collapse", "sidebar-closed");
            document.body.removeEventListener("click", closeSideBarOnBodyClick);
        }else{
            document.body.classList.add("sidebar-closed", "sidebar-collapse");
        }
    } else {
        document.body.classList.remove("sidebar-closed");
        const user = usePage().props.value.auth.user;

        if (user.collapse_menu === 0 ) {
            document.body.classList.remove("sidebar-collapse");
        }
    }
}

onMounted(() => {
    const user = usePage().props.value.auth.user;
    if(user.collapse_menu === 1){
        document.body.classList.add("sidebar-collapse");
    }
    window.addEventListener('resize', debounceResizeWindow);
    resizeWindow();
})


onUnmounted(() => {
    window.removeEventListener('resize', debounceResizeWindow);
})

</script>
<template>
    <div class="wrapper">

        <BoTopNavBar :collapsed="$page.props.auth.user.collapse_menu === 1" @sidebar-toggle-collapse="toggleSidebar"/>
        <BoMainNavigation ref="rootsidebar"/>
        <div class="content-wrapper h-100">
            <section class="content-header" v-if="$slots.page_title || hasBreadcrumb">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div :class="{'col-sm-6' : hasBreadcrumb, 'col-sm-12' : !hasBreadcrumb}">
                            <h1>
                                <slot name="page_title"/>
                            </h1>
                        </div>
                        <div class="col-sm-6" v-if="hasBreadcrumb">
                            <BoBreadcrumb/>
                        </div>
                    </div>
                </div>
            </section>
            <section class="content">
                <slot/>
            </section>
        </div>

        <p id="app_version">
            version {{ $page.props.app.version }}
        </p>

        <notifications classes="my-notification" position="bottom right"/>
    </div>
</template>
