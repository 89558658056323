const build_all_organisme_options_list = (list) => {
    if(!list){
        return [];
    }
    const data = list.map((organisme) => {
        return {label: organisme.meta_value, value: organisme.user_id}
    })
    if (data.length > 1) {
        data.unshift({label: 'Sélectionnez un organisme', value: null})
    }
    return data;
}

export {build_all_organisme_options_list}
