<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {usePage} from "@inertiajs/inertia-vue3";
import Pagination from "@/Components/Pagination.vue";
import DiagTags from "@/Pages/Diagnostics/partials/DiagTags.vue";
import {hasRole} from "@/Helpers/useRoles.js";
import ICheckbox from "@/Components/ICheckbox.vue";
import {debounce, throttle} from "lodash/function";
import Modal from "@/Components/Modal.vue";
import {useSynchroLocalData} from "@/services/synchro_data"
import {Inertia} from "@inertiajs/inertia";
import {notify} from "@kyvg/vue3-notification";
import {find, pickBy} from "lodash";
import DiagnosticInformations from "@/Pages/Diagnostics/partials/DiagnosticInformations.vue";

const emit = defineEmits(['editItem', 'clickOnDiagnostic', 'ask_for_sync_to_local']);

// Diagnostics reçu par le controller
const diagnostics = computed(() => usePage().props.value.diagnostics);

// Nombre total de diagnotics sur la page du tableau
const nb_total_diagnostics_current_page = computed(() => diagnostics.value.data.length);

// Liste des ids de diagnotics sur la page du tableau
const diagIdOnPage = computed(() => diagnostics.value.data.map(diag => diag.id));

// Diagnostics sélectionnés actuellement sur la page en cours
const diagSelectedOnCurrentPage = computed(() => selected_diag.value.filter(diag => diagIdOnPage.value.includes(diag.id)));

//liste des départements
const departmentsList = computed(() => usePage().props.value.departmentsList);

//liste des années de campagne
const anneesList = computed(() => usePage().props.value.anneesList);

const props = defineProps({
    currentItem: {
        type: Object,
        default: null
    },
    display_edit_button: {
        type: Boolean,
        default: false
    }
});

/**
 * Filter received from the controller
 * @type {ComputedRef<unknown>}
 */
const filters = computed(() => usePage().props.value.filters);
const msg_nb_selected = computed(() => {
    const count_selected = selected_diag.value.length;
    return (count_selected === 1 ? `Un diagnostic sélectioné` : `${count_selected} diagnostics sélectionnés`);

})
// Tableau contenant la liste des diagnostics cochés toute page confondue
const selected_diag = ref([]);

//const local_diagnostics = ref(null);


const editItem = (item) => emit("editItem", item);

/**
 * Handler sur le checkbox toggleAll
 */
const clickCheckAllHandler = () => {
    // Deletes selected diagnostics from the current page.
    let new_selected = selected_diag.value.filter(diag => {
        // console.log(diag.id, diagIdOnPage.value);
        return !diagIdOnPage.value.includes(diag.id)
    });
    // If none or more diagnostics are selected on the current page, all diagnostics are selected.
    if (diagSelectedOnCurrentPage.value.length !== nb_total_diagnostics_current_page.value) {
        new_selected = [...new_selected, ...diagnostics.value.data]
    }
    selected_diag.value = new_selected;
}

// Reférence pour la checkbox select all
const check_all = ref(false);
const checkUncheckAllToggle = () => check_all.value = diagSelectedOnCurrentPage.value.length === nb_total_diagnostics_current_page.value

watch(diagSelectedOnCurrentPage, () => checkUncheckAllToggle())
watch(selected_diag, (diags) => emit("clickOnDiagnostic", diags))

// Suppression d'un diagnostics
const itemToDelete = ref(null);
const diagnotic_to_delete_name = ref('');
const formDeleteProcessing = ref(false);
const openSupressModal = (item) => {
    editItem(null);
    itemToDelete.value = item;
    diagnotic_to_delete_name.value = item.name;
};
const closeSupressModal = () => itemToDelete.value = null;
const showSuppressModal = computed(() => itemToDelete.value !== null)
const submitDelete = () => {
    const {deleteDiagnostic} = useSynchroLocalData();
    deleteDiagnostic(itemToDelete.value.id)
        .then(() => {
            notify({
                title: "Diagnostic supprimé",
                text: "Le diagnostic a bien été supprimé",
                type: "success",
            })
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            itemToDelete.value = null;
            Inertia.visit(route('admin.diagnotics.index'),
                {
                    only: ['diagnostics'],
                    replace: true,
                    preserveState: true,
                })
        })
}


const options_diag_quali = [
    {label:"Tous", value:'all'},
    {label:"Avec quali", value:'1'},
    {label:"Sans quali", value:'0'},
]
const options_exploit_model = [
    {label:"Toutes", value:'all'},
    {label:"Exploitation modèle uniquement", value:'1'},
]
const options_type = [
    {label:"Tous", value:'all'},
    {label:"Global", value:'1'},
    {label:"Partiel", value:'0'},
]
const options_donnees = [
    {label:"Toutes", value:'all'},
    {label:"Données privées", value:'1'},
    {label:"Données libres", value:'0'},
]

const options_etats = [
    {label:"Tous", value:'all'},
    {label:"Finalisés", value:'1'},
    {label:"En cours", value:'0'},
]

const options_valides = [
    {label:"Tous", value:'all'},
    {label:"Oui", value:'1'},
    {label:"Non", value:'0'},
]

const sync_local_options = [
    {label:"Tous", value:'all'},
    {label:"Oui", value:'1'},
    {label:"Non", value:'0'},
]

// Gestion et sauvegarde de la liste des diagnostics à synchroniser manuellement pour les admins
const admin_diag_for_sync = ref(usePage().props.value.diagnostics_for_sync);
const updateAdminDiagSyncRequest = debounce(() => {
    console.log(admin_diag_for_sync.value);
    axios.post(route('admin.diagnotics.update_sync_list'), {sync_list: admin_diag_for_sync.value})
        .then((res) => {
            emit("ask_for_sync_to_local")
        })
}, 500);
watch(admin_diag_for_sync, () => updateAdminDiagSyncRequest())


/**
 * Params send to the controller
 * @type {Ref<UnwrapRef<{search, field: *, direction}>>}
 */
const params = ref({
    search:filters.value.search,
    field:filters.value.field,
    direction:filters.value.direction,
    diag_with_quali: filters.value.diag_with_quali ?? 'all',
    exploit_modele: filters.value.exploit_modele ?? 'all',
    is_global: filters.value.type ?? 'all',
    has_private_data: filters.value.has_private_data ?? 'all',
    is_valide: filters.value.is_valide ?? 'all',
    conseiller_validated: filters.value.conseiller_validated ?? 'all',
    department: filters.value.department ?? 'all',
    campaign_year: filters.value.campaign_year ?? 'all',
    sync_local: filters.value.sync_local ?? 'all'
});

/**
 * Sort handler on columns header
 * @param field
 */
const sort = (field) => {
    editItem(null);
    params.value.field = field
    params.value.direction = params.value.direction === 'asc' ? 'desc' : 'asc';
}

const getExploitantDpt = (user_metas) => {
    const meta = user_metas.filter(m => m.meta_key === 'dpt_exploitation')[0];
    if(meta){
        return meta.meta_value;
    }
    return null;
}

/**
 * Watcher for params
 * Make an Inertia request with cleaned params
 */
watch(params, throttle(function (){
    //clean empty params
    let my_params = pickBy(params.value);

    if(my_params.search === ''){
        delete  my_params.search
    }
    if(my_params.diag_with_quali === 'all'){
        delete  my_params.diag_with_quali
    }
    if(my_params.exploit_modele === 'all'){
        delete  my_params.exploit_modele;
    }
    if(my_params.is_global === 'all'){
        delete my_params.is_global;
    }
    if(my_params.has_private_data === 'all'){
        delete my_params.has_private_data;
    }
    if(my_params.is_valide === 'all'){
        delete my_params.is_valide;
    }
    if(my_params.conseiller_validated === 'all'){
        delete my_params.conseiller_validated;
    }
    if(my_params.department === 'all'){
        delete my_params.department;
    }
    if(my_params.campaign_year === 'all'){
        delete my_params.campaign_year;
    }
    if(my_params.sync_local === 'all'){
        delete my_params.sync_local;
    }


    //request
    Inertia.get(route('admin.diagnotics.index'), my_params, {replace:true, preserveState:true})
}, 300), {deep:true})

</script>

<template>
    <Modal id="suppress_modal" :show="showSuppressModal" @close="closeSupressModal">
        <template #title>
            <i class="fas fa-trash-alt"></i> Suppression d'un diagnostic
        </template>
        <template #content>
            <form @submit.prevent="submitDelete">
                <div class="modal-body">
                    Voulez-vous vraiment supprimer le diagnostic "<strong class="text-info">{{
                        diagnotic_to_delete_name
                    }}</strong>" ?
                </div>
                <div class="modal-footer">
                    <button type="button" @click="closeSupressModal" class="btn btn-secondary">Annuler</button>
                    <button type="submit" :disabled="formDeleteProcessing" class="btn btn-danger">Supprimer</button>
                </div>
            </form>
        </template>
    </Modal>

    <div class="card animate">
        <div class="card-header">
            <h3 class="card-title"><i class="fas fa-list"></i> Liste des diagnostics</h3>
            <span v-if="selected_diag.length" class="float-right badge badge-info">{{ msg_nb_selected }}</span>
        </div>
        <div class="card-body">
            <div id="diagnostics_wrapper" class="dataTables_wrapper dt-bootstrap4" >
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-inline mb-2">
                            <label for="diag-quali-filter" class="font-weight-normal">Module quali : </label>
                            <select v-model="params.diag_with_quali" name="diag-quali-filter" id="diag-quali-filter" class="form-control form-control-sm ml-1">
                                <option v-for="opt in options_diag_quali" :key="opt.value" :value="opt.value">{{opt.label}}</option>
                            </select>
                        </div>
                        <div class="form-inline mb-2">
                            <label for="diag-exploit-model-filter" class="font-weight-normal">Exploitation modèle : </label>
                            <select v-model="params.exploit_modele" name="diag-exploit-model-filter" id="diag-exploit-model-filter" class="form-control form-control-sm ml-1">
                                <option v-for="opt in options_exploit_model" :key="opt.value" :value="opt.value">{{opt.label}}</option>
                            </select>
                        </div>
                        <div class="form-inline mb-2">
                            <label for="diag-exploit-model-filter" class="font-weight-normal">Type : </label>
                            <select v-model="params.is_global" name="diag-type-filter" id="diag-type-filter" class="form-control form-control-sm ml-1">
                                <option v-for="opt in options_type" :key="opt.value" :value="opt.value">{{opt.label}}</option>
                            </select>
                        </div>
                        <div class="form-inline mb-2">
                            <label for="diag-has-private-data-filter" class="font-weight-normal">Données : </label>
                            <select v-model="params.has_private_data" name="diag-has-private-data-filter" id="diag-has-private-data-filter" class="form-control form-control-sm ml-1">
                                <option v-for="opt in options_donnees" :key="opt.value" :value="opt.value">{{opt.label}}</option>
                            </select>
                        </div>
                        <div class="form-inline mb-2">
                            <label for="diag-is-valide-filter" class="font-weight-normal">Etat : </label>
                            <select v-model="params.is_valide" name="diag-is-valide-filter" id="diag-is-valide-filter" class="form-control form-control-sm ml-1">
                                <option v-for="opt in options_etats" :key="opt.value" :value="opt.value">{{opt.label}}</option>
                            </select>
                        </div>
                        <div class="form-inline mb-2">
                            <label for="diag-conseiller-validated-filter" class="font-weight-normal">Diagnostics validés : </label>
                            <select v-model="params.conseiller_validated" name="diag-conseiller-validated-filter" id="diag-conseiller-validated-filter" class="form-control form-control-sm ml-1">
                                <option v-for="opt in options_valides" :key="opt.value" :value="opt.value">{{opt.label}}</option>
                            </select>
                        </div>
                        <div class="form-inline mb-2">
                            <label for="diag-department-filter" class="font-weight-normal">Département : </label>
                            <select v-model="params.department" name="diag-department-filter" id="diag-department-filter" class="form-control form-control-sm ml-1">
                                <option :value="'all'">Tous</option>
                                <option :value="'null'">Non défini</option>
                                <option v-for="opt in departmentsList" :key="opt.num_dep" :value="opt.num_dep">{{opt.dep_name}}</option>
                            </select>
                        </div>
                        <div class="form-inline mb-2">
                            <label for="diag-campaign-year-filter" class="font-weight-normal">Années de campagne : </label>
                            <select v-model="params.campaign_year" name="diag-campaign-year-filter" id="diag-campaign-year-filter" class="form-control form-control-sm ml-1">
                                <option :value="'all'">Toutes</option>
                                <option :value="'null'">Non définie</option>
                                <option v-for="opt in anneesList" :key="opt" :value="opt">{{opt}}</option>
                            </select>
                        </div>
                        <div class="form-inline mb-2" v-if="hasRole('admin') || hasRole('super-admin')">
                            <label for="diag-campaign-sync-local-filter" class="font-weight-normal">Diagnostics synchronisés : </label>
                            <select v-model="params.sync_local" name="diag-campaign-sync-local-filter" id="diag-campaign-sync-local-filter" class="form-control form-control-sm ml-1">
                                <option v-for="opt in sync_local_options" :key="opt.value" :value="opt.value">{{opt.label}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div id="diagnostics_filter" class="dataTables_filter">
                            <label for="search_diagnotics_input">Rechercher dans la liste :</label>
                            <input type="search" id="search_diagnotics_input" class="form-control form-control-sm"
                                   v-model="params.search" placeholder=""
                                   aria-controls="diagnostics">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="table-responsive">
                            <table id="diagnostics"
                                   class="table table-bordered table-hover table-striped dataTable dtr-inline table-sm"
                                   role="grid" aria-describedby="diagnostics_info">
                                <thead>
                                <tr role="row">
                                    <th tabindex="0"
                                        aria-controls="diagnostics"
                                        class="checkbox_col text-center"
                                        @click="sort('name')">
                                        <input type="checkbox" @change="clickCheckAllHandler" v-model="check_all"/>
                                    </th>
                                    <th tabindex="0"
                                        aria-controls="diagnostics"
                                        class="sorting"
                                        :class="{'sorting_asc': params.field === 'name' && params.direction === 'asc','sorting_desc': params.field === 'name' && params.direction === 'desc' }"
                                        @click="sort('name')">
                                        Nom diagnotic
                                    </th>
                                    <th tabindex="0"
                                        aria-controls="diagnostics">
                                        Nom exploitant
                                    </th>
                                    <th tabindex="0"
                                        aria-controls="diagnostics">
                                        Informations
                                    </th>
                                    <th tabindex="0"
                                        aria-controls="diagnostics">
                                        Tags
                                    </th>
                                    <th v-if="hasRole('admin') || hasRole('super-admin')"
                                        aria-controls="diagnostics">
                                        Sync. local
                                    </th>
                                    <th tabindex="0"
                                        aria-controls="actions">
                                        Actions
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr :class="{'table-primary' : props.currentItem === diagnostic}"
                                    v-if="diagnostics.data.length"
                                    v-for="(diagnostic, index) in diagnostics.data" :key="diagnostic.id">
                                    <td tabindex="0" class="checkbox_col text-center">
                                        <input type="checkbox" :value="diagnostic" v-model="selected_diag"/>
                                    </td>
                                    <td tabindex="0">{{ diagnostic.name }}</td>
                                    <td tabindex="0">
                                        {{ diagnostic.exploitant.fullname }}
                                        <br>
                                        Dpt : {{getExploitantDpt(diagnostic.exploitant.user_metas)}}
                                    </td>
                                    <td tabindex="0">
                                        <DiagnosticInformations :diagnostic="diagnostic"/>
                                    </td>
                                    <td tabindex="0">
                                        <DiagTags :diagnostic="diagnostic"/>
                                    </td>
                                    <td tabindex="0" class="text-center"
                                        v-if="hasRole('admin') || hasRole('super-admin')">
                                        <ICheckbox :id="`sync-${diagnostic.id}`" v-model="admin_diag_for_sync"
                                                   :value="diagnostic.id"/>
                                    </td>
                                    <td tabindex="0" class="text-center">
                                        <button v-if="props.display_edit_button" class="btn btn-default btn-sm"
                                                type="button" @click="editItem(diagnostic)"><i class="fas fa-pen"></i>
                                        </button>
                                        <button class="btn btn-default btn-sm" type="button"
                                                @click="openSupressModal(diagnostic)"><i class="fas fa-trash-alt"></i>
                                        </button>
                                    </td>
                                </tr>
                                <tr v-else>
                                    <td colspan="7" class="p-5 text-center text-uppercase">Aucun diagnostic</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-5">
                        <div class="dataTables_info mb-2 mb-sm-0" id="diagnostics_info" role="status"
                             aria-live="polite">{{ __('ui.administrator.DisplayOf') }} {{ diagnostics.from }}
                            {{ __('ui.administrator.To') }} {{ diagnostics.to }} {{ __('ui.administrator.On') }}
                            {{ diagnostics.total }}
                            {{ diagnostics.total > 1 ? __('ui.administrator.Entries') : __('ui.administrator.Entry') }}
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-7">
                        <Pagination class="mt-2 mb-sm-0" id="diagnostics_paginate" :items="diagnostics"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
